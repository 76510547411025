import styled from 'styled-components'
import { Button } from 'components'
import { mykonosBlue } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'
import { XSign } from '@qlue/icon-kit'

export const Wrapper = styled.div`
  z-index: 1001;
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-items: center;
  overflow-y: auto;
  background-color: rgba(63, 63, 63, 0.3);

  h2 {
    margin: 0.5rem 0;
    font-weight: 500;
    font-size: 1.4rem;
    letter-spacing: 0.25px;
  }

  ${IS_FRAME_MOBILE} {
    input {
      font-size: 0.77rem;
    }

    h2 {
      margin: 0.5rem 0;
      font-weight: 500;
      letter-spacing: 0.25px;
      font-size: 1.1rem;
    }
  }
`

export const IconClose = styled(XSign)`
  cursor: pointer;
  display: block;
  margin-left: auto;
`

export const Form = styled.form`
  border-radius: 4px;
  margin: auto;
  height: max-content; 
  background-color: white;
  padding: 1rem 1.5rem;
  min-width: 37rem;
  width: 35%;

  div {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  ${IS_FRAME_TABLET} {
    min-width: unset;
    width: 55%;
  }

  ${IS_FRAME_MOBILE} {
    min-width: unset;
    width: 80%;
    padding: 1rem;

    div {
      font-size: 0.77rem;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  margin-top: 1rem;
  
  span {
    font-size: 0.875rem;
    letter-spacing: 0.25px;
  }

  ${IS_FRAME_TABLET} {
    display: block;
  }

  ${IS_FRAME_MOBILE} {
    display: block;

    span {
     font-size: 0.77rem !important; 
    }
  }
`

export const PhoneNumberWrapper = styled.div`
  margin-top: 0.5rem;
`

export const BankSelectWrapper = styled.div`
  margin-top: 0.5rem;

  > span {
    color: #222b49;
  }

  ${IS_FRAME_MOBILE} {
    margin-bottom: 1rem;
  }
`

export const SubmitButton = styled(Button)`
  color: ${mykonosBlue};
  font-weight: bold;
  letter-spacing: 0.29px;
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  padding: 0.5rem 4rem;
  font-size: 0.875rem;

  &:not([disabled]):hover {
    color: #fff;
    background-color: ${mykonosBlue};
    border: solid 1px #fff;
  }

  ${IS_FRAME_TABLET} {
    width: 100%;
    margin: 1rem 0 1rem;
  }

  ${IS_FRAME_MOBILE} {
    font-size: 0.77rem;
    width: 100%;
    margin: 1rem 0 1rem;
  }
` 
