import * as Yup from 'yup'

export const CompleteRegisterSchema = Yup.object().shape({
  bank_account_name: Yup.string()
    .min(4, 'Minimum 4 characters')
    .max(30, 'Maximum 30 characters')
    .required('Required'),
  npwp: Yup.string()
    .matches('^[0-9]*$', 'Your npwp is invalid')
    .max(30, 'Maximum 30 digits')
    .required('Required')
    .test(
      'empty-or-4-characters-check',
      'Minimum 4 digits',
      (npwp) => !npwp || npwp.length >= 4
    ),
  bank_account: Yup.string()
    .required('Required')
    .matches('^[0-9]*$', 'Your bank account number is invalid')
    .min(6, 'Minimum 6 digits')
    .max(18, 'Maximum 18 digits'),
  affiliate_bank: Yup.object().shape({
    id: Yup.string()
      .required('Required')
    
  })
})
