import React, { useCallback, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useForm, Controller } from 'react-hook-form'
import { 
  InputText, 
  Select
} from 'components'
import {
  Wrapper,
  Form,
  Grid,
  BankSelectWrapper,
  SubmitButton,
  IconClose
} from './styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { AffiliateProfileURL, AffiliateRegisterURL, BankURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'
import { CompleteRegisterSchema } from './complete-register-schema'

const CompleteRegisterFormModal = ({
  isOpen,
  setIsOpen,
  fetchAnalytics
}) => {
  
  const [banks, setBanks] = useState([])
  const { addToast } = useToasts()

  const fetchBankList = () => {
    AffiliateAPI.get(BankURL.GetListBank).then((response) => {
      const data = response.data.banks
      setBanks(data)
    })
  }

  const fetchProfile = useCallback(async() => {
    await AffiliateAPI.get(AffiliateProfileURL.GetProfile )
      .then((response) => {
        const data = response.data

        // assign BE data to react form
        const keys = Object.keys(data)
        
        if (keys.length) {
          keys.map((key) => {
            const value = data[key]
            if(value) setValue(key, value)
            return ''
          })
        }
      })
      .catch((error) => {
        if(error.response.status !== 403){
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchBankList()
    fetchProfile()
  }, [fetchProfile])


  const handleCompleteRegisterData = async ({ values }) => {
    await AffiliateAPI.put(AffiliateRegisterURL.PostCompleteRegister, values)
      .then(() => {
        addToast('Successfully completed your data', { appearance: 'success' })
        setIsOpen(false)
        fetchAnalytics()
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          addToast(`${error.message}. Please Check Your Internet Connection`, {
            appearance: 'warning'
          })
        } else {
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })
  }

  const handleSubmitForm = async (values, e) => {
    values.affiliate_bank_id = values.affiliate_bank?.id

    handleCompleteRegisterData({ values })
  }

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
    getValues
  } = useForm({
    resolver      : yupResolver(CompleteRegisterSchema),
    mode          : 'onTouched',
    defaultValues : {
      bank_account_name : '',
      npwp              : undefined,
      affiliate_bank    : {
        code : '',
        id   : '',
        name : ''
      },
      bank_account: undefined
    }
  })

  return (
    <Wrapper
      isOpen={ isOpen }
    >
      <Form 
        onSubmit={ handleSubmit(handleSubmitForm) } 
        autoComplete='off'
      >
        <IconClose onClick={ () => setIsOpen(false) }/>
        <h2>
          One more step
        </h2>
        <div>
          Please fill in the details below so we can process your application faster.
        </div>
        <Grid>
          <BankSelectWrapper>
            <span>Bank Name</span>
            <Controller
              render={ () => (
                <Select
                  type='text'
                  withSearch
                  title='Bank'
                  keySearch='name'
                  svgPosition={{ top: '5px' }}
                  borderColor='#bcbfc8'
                  py='4px'
                  options={ banks }
                  selectedItem={
                    getValues().affiliate_bank?.name
                      ? getValues().affiliate_bank
                      : { name: 'Select bank' }
                  }
                  selectedKey='name'
                  errorText={ errors?.affiliate_bank?.id ? errors.affiliate_bank.id.message  : '' }
                  onChange={ (bank) => {
                    setValue('affiliate_bank', bank)
                  } }
                />
              ) }
              control={ control }
              name='affiliate_bank'
            />
          </BankSelectWrapper>
          
          <Controller
            render={ ({ field }) => (
              <InputText
                title={ 'Bank Account Number' }
                placeholder='Enter bank account number here'
                error={ errors?.bank_account ? errors.bank_account?.message : '' }
                ref={ field.ref }
                { ...field }
                onChange={ (e) => setValue('bank_account', e.target.value.replace(/\D/, '')) }
              />
            ) }
            control={ control }
            name='bank_account'
          />

          <Controller
            render={ ({ field }) => (
              <InputText
                title='Bank Account Name'
                placeholder='Enter bank account name here'
                error={ errors?.bank_account_name?.message ? errors.bank_account_name.message : '' }
                ref={ field.ref }
                { ...field }
              />
            ) }
            control={ control }
            name='bank_account_name'
          />

          <Controller
            render={ ({ field }) => (
              <InputText
                title={ 'TIN' }
                placeholder='Enter Tax Identification Number (TIN) here'
                error={ errors?.npwp ? errors.npwp.message : '' }
                ref={ field.ref }
                { ...field }
                // always put onChange below {...field}
                onChange={ (e) => setValue('npwp', e.target.value.replace(/\D/, '')) }
              />
            ) }
            control={ control }
            name='npwp'
          />
        </Grid>
        <SubmitButton
          type='submit'
          buttonType='warning'
          disabled={ isSubmitting }
          isLoading={ isSubmitting }
        >
            Save
        </SubmitButton>
      </Form>
    </Wrapper>
  )
}

export default CompleteRegisterFormModal
