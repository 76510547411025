import React from 'react'
import { AffiliateDashboardLayout } from 'components/AffiliateDashboard'
import AnalyticsView from 'domains/AffiliateDashboard/Analytics'
import withAuth from '../../hocs/with-auth'

const redirectUrl = '/affiliate-dashboard/login?redirectUrl=/affiliate-dashboard/analytics'

const Analytics = ({ location }) => (
  <AffiliateDashboardLayout>
    <AnalyticsView location={ location }/>
  </AffiliateDashboardLayout>
)

export default withAuth(Analytics, redirectUrl)
