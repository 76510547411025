import { 
  ArrowRight, 
  IconCopy, 
  IconInfo,
  IconEditNote 
} from '@qlue/icon-kit'
import React, { useEffect, useState , useCallback } from 'react'
import {
  Wrapper,
  MenuWrapper,
  Box,
  MenuTitle,
  MenuData,
  DetailLink,
  Flex,
  Reminder,
  LinkContainer
} from './styles'
import CustomerIcon from './media/customer-icon.svg'
import PayoutIcon from './media/payout-icon.svg'
import { AffiliateAnalyticsURL } from 'commons/API'
import { useAuth } from '../../../providers/auth-provider'
import { formatMoney } from 'utils'
import { useToasts } from 'react-toast-notifications'
import { Shimmer } from 'components'
import AffiliateAPI from 'commons/API/affiliate'
import { CompleteRegisterFormModal } from './components'

const AFFILIATE_STATUS = {
  ACTIVE  : 'Active',
  PENDING : 'Request to Join'
}

const AnalyticsView = ({ location }) => {

  const { addToast } = useToasts()

  const { user } = useAuth()
  const [data, setData] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  // handle pop up complete register modal form after success set password
  useEffect(() => {
    // handle browser navigate back
    window.onpopstate = () => {
      setIsOpen(false)
    }

    if(!location.state?.show_pop_up) {
      setIsOpen(false)
    }
    else if(location.state?.show_pop_up) setIsOpen(true)
    else setIsOpen(false)
 
  }, [location])

  const disabled = user?.affiliate_status?.name === AFFILIATE_STATUS.PENDING

  const fetchAnalytics = useCallback(async () => {
    setIsLoading(true)
    await AffiliateAPI.get(AffiliateAnalyticsURL.GetAnalytics)
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        if(error.response.status !== 403) {
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })
      .finally(() => setIsLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAnalytics()
  }, [fetchAnalytics])

  const WelcomingText = () => {
    if(disabled) {
      return (
        <Flex>
          <IconInfo />
          <div>
            <p>
              Thanks for your interest joining our affiliate program. { ' ' }
              <strong>
                Our internal team still review your application.
              </strong>
            </p>
            <p>
              We will inform you through email if we have finished review your affiliate request.
            </p>
          </div>
        </Flex>
      )
    }
    return null
  }

  return(
    <Wrapper showTooltip={ showTooltip }>
      <h1>
        Welcome to Qlue Affiliate dashboard
      </h1>
      <WelcomingText />

      {
        isLoading
          ? (
            <>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
            </>
          )
          : (
            <>
              { 
                !data.completed_data && (
                  <>
                    <CompleteRegisterFormModal 
                      isOpen={ isOpen }
                      setIsOpen={ setIsOpen }
                      fetchAnalytics={ fetchAnalytics }
                    />
                    <Reminder>
                      <IconEditNote />
                      <p>
                        Please complete the following NPWP, Bank Name and Bank Account Number information. { ' ' }
                        <span onClick={ () => setIsOpen(true) }>
                          Click here
                        </span>
                      </p>
                    </Reminder> 
                  </>
                ) 
              }

              <MenuWrapper>
                <Box disabled={ disabled }>
                  <img src={ CustomerIcon } alt='customer' />
                  <div>
                    <MenuTitle>
                    Total of customer
                    </MenuTitle>
                    <MenuData>
                      { data.total_client }
                    </MenuData>
                    <DetailLink to='/affiliate-dashboard/clients'>
                    Details
                      <ArrowRight />
                    </DetailLink>
                  </div>
                </Box>

                <Box disabled={ disabled }>
                  <img src={ PayoutIcon } alt='payout' />
                  <div>
                    <MenuTitle>
                    Your balance
                    </MenuTitle>
                    <MenuData>
                      { formatMoney(data.fee_balance_idr, { currency: 'IDR' }) }
                      <div>
                        { formatMoney(data.fee_balance_usd, { currency: 'USD' }) }
                      </div>
                    </MenuData>
                    <LinkContainer>
                      <DetailLink to='/affiliate-dashboard/transactions'>
                        Transactions
                        <ArrowRight />
                      </DetailLink>
                      <DetailLink to='/affiliate-dashboard/payouts'>
                        Payouts
                        <ArrowRight />
                      </DetailLink>
                    </LinkContainer>
                  </div>
                </Box>

                <Box.Referral disabled={ disabled }>
                  <div>
                    <MenuTitle>
                    Your referral code
                    </MenuTitle>
                    <MenuData>
                      { data.referral_code || '-' }
                    </MenuData>
                  </div>
                  <div 
                    className='tooltip'
                    onClick={ () => { 
                      navigator.clipboard.writeText(data.referral_code) 
                      setShowTooltip(true)
                    } }
                    onMouseLeave={ () => setShowTooltip(false) }
                  >
                    <IconCopy  />
                    <span className="tooltiptext">copied</span>
                  </div>
                </Box.Referral>
              </MenuWrapper>
            </>
          )
      }
    </Wrapper>
  )
}

export default AnalyticsView
