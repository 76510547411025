import styled from 'styled-components'
import { 
  lightDarkBlue, 
  mykonosBlue 
} from 'components/baseColor'
import { Link } from 'gatsby'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  margin: 4rem 8rem;
  color: ${lightDarkBlue};
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

  > h1 {
    letter-spacing: 0.25px;
    font-size: 2.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    margin-bottom: 0.75rem;
    width: 50%;
  }

  > p {
    line-height: 1.79;
    letter-spacing: 0.25px;
    font-size: 1rem;
    margin-top: 0;
    width: 50%;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    text-align: left;
    width: 100%;
  }

  .tooltip .tooltiptext {
    visibility: ${({ showTooltip }) => showTooltip ? 'visible' : 'hidden'};
    width: 4.5rem;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -2rem;
    left: -1.5rem;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  ${IS_FRAME_TABLET} {
    margin: 4rem 6rem;

    h1 {
      width: 100%;
      font-size: 1.75rem;
    }

    .tooltip {
      text-align: right;
      width: unset;
    }
  }

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1.5rem;

    > h1 {
      font-size: 1.5rem;
      width: 100%;
    }

    > p {
      width: 100%;
      font-size: 0.875rem;
    }

    .tooltip {
      text-align: right;
      width: unset;
    }
  }
`

export const MenuWrapper = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 2fr 3fr 1.25fr;
  margin: 3rem 0;

  ${IS_FRAME_TABLET} {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    margin: 1.5rem 0;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    margin: 1.5rem 0;
  }
`

export const Box = styled.div`
  border: 1px solid #dee2ee;
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: right;
  display: flex;
  justify-content: space-between;

  ${({ disabled }) => disabled && `
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.7;
  `}

  ${IS_FRAME_TABLET} {
    justify-content: space-between;

    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(3) {
      text-align: left;
      order: 1;
    }
  }

  ${IS_FRAME_MOBILE} {

    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(3) {
      order: 1;
    }

    > img {
      width: 4rem;
      height: 4rem;
      display: block;
      margin: auto 0;
    }
  }
`

Box.Referral = styled(Box)`
  background-color: rgba(222, 226, 238, 0.3);
  display: block;
   svg {
     cursor: pointer;
     margin-right: 0.75rem;
   }

  ${IS_FRAME_TABLET} {
    display: flex;
    align-items: center;
    margin: 0;

    svg {
      margin-left: auto;
      margin-right: 0;
    }
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    align-items: center;
    margin: 0;
  }
`

export const MenuTitle = styled.div`
  color: #71798a;
  letter-spacing: 0.25px;
  font-weight: 500;
  font-size: 0.875rem;
`

export const MenuData = styled.div`
  letter-spacing: 0.25px;
  font-size: 2.1rem;
  font-weight: normal;
  margin: 0.75rem 0; 

  > div {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    font-size: 1rem;
    margin-top: 0.75rem;
  }

  ${IS_FRAME_MOBILE} {
    font-size: 1.25rem;
  }
`

export const DetailLink = styled(Link)`
  color: ${mykonosBlue};
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  font-weight: 600;
  font-size: 0.875rem;
  justify-content: flex-end;
  text-decoration: none;

  > svg path {
    fill: ${mykonosBlue};
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 179, 50, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;

  p {
    font-size: 1rem;
    margin: 0.5rem 0;
    line-height: 1.5;
  }

  > svg {
    flex: 0 0 4rem;
    width: 3rem;
    height: 3rem;

    path {
      fill: orange;
    }
  }

  ${IS_FRAME_TABLET} {
    p {
      margin-top: 0;
    }

   align-items: flex-start;
  }

  ${IS_FRAME_MOBILE} {
     p {
      margin-top: 0;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    align-items: flex-start;
    flex: 0 0 1rem;

    > svg {
      width: 1.5rem;
      height 1.5rem;
      margin-left: -1rem;
    }
  }
`

export const Reminder = styled.div`
  background-color: rgb(18, 111, 250, 0.2);
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;

  > svg {
    flex: 0 0 2.75rem;
    display: block;
    margin-left: 1.25rem;

    path {
      fill: #1160fb;
    }
  }

  p {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
  }

   span {
    cursor: pointer;
    text-decoration: none;
    color: #1160fb;
    font-weight: bold;
  }

  ${IS_FRAME_TABLET} {
    align-items: flex-start;

    > svg {
      margin: 0;
    }
  }

  ${IS_FRAME_MOBILE} {

    align-items: flex-start;

    span, p {
      margin-top: 0;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    > svg {
      margin: 0;
    }
  }
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 13rem;
`
